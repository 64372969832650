import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImageSlide from "../../components/ImageSlide"

const Container = () => (
  <Layout>
    <SEO title="Home" />
    <div className="home">
      <header
        className="color-bg light-typo padding-top-bottom bg-image detail-header"
        style={{
          backgroundImage: "url(/images/rooms/flower/flower_02.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <h1 className="page-title">꽃이슬</h1>

          <p className="page-subtitle">
            육각형의 돔 형식으로 지어진 독특한 침실구조, 깔끔한 최신식 주방,
            창밖에는 보성의 청정자연이 한눈에 들어오는 1층 테라스를 이용하실 수
            있는 예쁜 방이예요.
          </p>
        </div>
      </header>

      <article className="post single-post">
        <div className="container scrollimation fade-up">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <div className="post-media">
                <ImageSlide
                  imageList={[
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/rooms/flower/flower_01.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">침대</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/rooms/flower/flower_02.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">침대</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/rooms/flower/flower_03.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">조명</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/rooms/flower/flower_04.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">침대</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/rooms/flower/flower_05.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">침대</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/rooms/flower/flower_06.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">TV</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/rooms/flower/flower_07.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">TV</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/rooms/flower/flower_08.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">주방 겸 거실</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/rooms/flower/flower_09.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">주방 겸 거실</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/rooms/flower/flower_10.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">주방 겸 거실</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/rooms/flower/flower_11.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">
                        거실 에서 보이는 앞 마당 풍경
                      </p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/rooms/flower/flower_12.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">주방 겸 거실</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/rooms/flower/flower_13.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">조리공간</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/rooms/flower/flower_14.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">화장실</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/rooms/flower/flower_15.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">화장실</p>
                    </li>,
                  ]}
                />
              </div>

              <div className="post-content">
                <div>
                  <h2 className="text-center">객실정보</h2>
                  <p>
                    <b>구조</b>: 거실+방 (침대룸1+화장실1)
                  </p>
                  <p>
                    <b>크기</b>: 36㎡ (11평)
                  </p>
                  <p>
                    <b>기준/최대인원</b>: 2명 / 4명
                  </p>
                  <p>
                    <b>구비시설</b>: TV, 에어컨, 온풍기, 냉장고, 전기밥솥,
                    커피포트, 인덕션, 전기쿠커, 냄비 등 조리기구, 헤어드라이어,
                    침대, 샤워부스, 수건, 샴푸 등 세면도구, 무선인터넷 가능
                  </p>
                </div>

                <div>
                  <h2 className="text-center">요금</h2>
                  <div>
                    <p className="text-danger">
                      기본요금
                      <span style={{ fontSize: "14px", color: "#7a7c7f" }}>
                        {" "}
                        *성수기: 7월 20일 ~ 8월 20일
                      </span>
                    </p>
                    <table className="table table-brodered">
                      <tbody>
                        <tr className="active">
                          <td>기간명</td>
                          <td>주중</td>
                          <td>금요일</td>
                          <td className="info">토요일</td>
                          <td className="danger">일요일</td>
                        </tr>
                        <tr className="">
                          <td>성수기</td>
                          <td>120,000원</td>
                          <td>120,000원</td>
                          <td>120,000원</td>
                          <td>120,000원</td>
                        </tr>
                        <tr className="">
                          <td>비수기</td>
                          <td>80,000원</td>
                          <td>100,000원</td>
                          <td>100,000원</td>
                          <td>80,000원</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <p className="text-danger">
                      추가요금 (인원추가)
                      <span style={{ fontSize: "14px", color: "#7a7c7f" }}>
                        {" "}
                        *영,유아도 추가유료 인원에 포함됩니다.
                      </span>
                    </p>
                    <table className="table table-brodered">
                      <tbody>
                        <tr class>
                          <td>성인</td>
                          <td className="active">10,000원</td>
                        </tr>
                        <tr>
                          <td>아동</td>
                          <td className="active">10,000원</td>
                        </tr>
                        <tr>
                          <td>유아</td>
                          <td className="active">10,000원</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <a className="btn btn-qubico" href="/reservation/reserve">
                  <i className="fa fa-shopping-cart"></i>예약하기
                </a>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </Layout>
)

export default Container
